
.imgDiv {
  position: relative;
  width: 131px;
  height: 131px;
  border-radius: 2rem;
  /* box-shadow: 0 12px 36px rgb(0 0 0 / 16%), 0 8px 8px rgb(0 0 0 / 23%); */
  z-index: 10;
}


.displayName{
  position: absolute;
  font-family: audiowide;
  -webkit-font-smoothing : "auto";
  font-size: 12px;
  text-align: center;
  color : #1E417C;
  left: 0px;
  right: 0px;
  bottom: 7px;
  z-index: 1;
}
.width{
  width : 96%;
}
@media only screen and (max-width: 1500px) {
  .imgDiv {
    width: 110px;
    height: 110px;
    border-radius: 2px;
  }
}
@media only screen and (max-width: 1300px) {
  .imgDiv {
    width: 95px;
    height: 95px;
    border-radius: 1rem;
  }
  .displayName{
    font-size: 10px;
    bottom: 7px;
  }
}
@media only screen and (max-width: 1100px) {
  .imgDiv {
    width: 75px;
    height: 75px;
    border-radius: 1rem;
  }
  .displayName{
    font-size: 8px;
    bottom: 9px;
  }
}
@media only screen and (max-width: 900px) {
  .imgDiv {
    width: 60px;
    height: 60px;
    border-radius: 1px;
  }
  .displayName{
    font-size: 7px;
  }
}
 @media only screen and (max-width: 768px) {
   .mobileView {

    display: grid;
    place-items: center;
    max-height: 500px;
    overflow: hidden;
    margin:2em auto;

  }
  .mobileView  img {
    width: 100px;
    height: 100px;
    border-radius: 1.3rem;
    margin-bottom: 1.1rem;

  }
  .displayName{
    text-align: center;
    bottom:25px;
  }
}
@media only screen and (max-width: 420px) {
  .mobileView{
    max-height: 500px;
    overflow: hidden;
    display: grid;
  }
  .mobileView img {
    width: 90px;
    height: 90px;
    margin-bottom: 1.1rem;
    border-radius: 15px;
  }
}
